import React from 'react'
import Layout from '../components/layout'

const Hub = () => (
    <Layout>
        
        



    </Layout>
)


export default Hub;